import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { AllSiteData, EdgeNode } from '../../types/QueryTypes';
import topMessage from '../../assets/top_message.png';
import topSilhouette from '../../assets/top_silhouette.png';
import { TopicContainer, TopicsContainer, TopContainer } from './style';

type TopicProps = {
  node: EdgeNode;
};

const Topic: React.FC<TopicProps> = ({ node }) => (
  <TopicContainer className="row">
    <span className="col-xs-5 col-sm-3 published-date">
      {node.frontmatter.date}
    </span>
    <span className="col-xs-7 col-sm-9 title">
      <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
    </span>
  </TopicContainer>
);

const Topics: React.FC = () => {
  const data: AllSiteData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [fields___slug], order: DESC }) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "YYYY年MM月DD日")
            }
          }
        }
      }
    }
  `);
  return (
    <TopicsContainer>
      <h2 className="wow bounceInRight" data-wow-delay="1.0s">
        Topics
      </h2>
      {data.allMarkdownRemark.edges.map(edge => (
        <Topic node={edge.node} key={edge.node.id} />
      ))}
    </TopicsContainer>
  );
};

const Top: React.FC = () => (
  <TopContainer>
    <div id="main-content">
      <ul>
        <li>
          <img
            src={topMessage}
            alt="message"
            className="wow fadeInDown resizable"
            data-wow-duration="2s"
            data-wow-delay="0.1s"
          />
        </li>
        <li>
          <img
            src={topSilhouette}
            alt="silhouette"
            className="wow fadeInRight resizable"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
            data-wow-offset="10"
          />
        </li>
      </ul>
    </div>
    <div id="main-bottom">
      <div id="topics-list" className="row">
        <Topics />
      </div>
    </div>
  </TopContainer>
);

export default Top;
