import React from 'react';
import AnchorTarget from '../components/AnchorTarget';
import { Section } from '../types/PropTypes';
import Layout from '../components/Layout';
import Top from '../sections/Top';
import Business from '../sections/Business';
import Message from '../sections/Message';
import Company from '../sections/Company';

const sections: Section[] = [
  { id: 'top', label: 'Top' },
  { id: 'business', label: '事業内容' },
  { id: 'message', label: 'Message' },
  { id: 'company', label: '会社概要' },
];

const Index: React.FC = () => {
  return (
    <Layout sections={sections}>
      <AnchorTarget id="top" />
      <Top />
      <Business />
      <Message />
      <Company />
    </Layout>
  );
};

export default Index;
