import React from 'react';
import Iframe from 'react-iframe';
import { Link } from 'gatsby';
import AnchorTarget from '../../components/AnchorTarget';
import Container from './style';

const Company: React.FC = () => (
  <Container>
    <AnchorTarget id="company" />
    <div className="container">
      <div className="section-content">
        <h1 className="wow fadeInDown">会社概要</h1>
        <table className="wow fadeInDown" data-wow-duration="0.8s">
          <tbody>
            <tr>
              <th>社名</th>
              <td>株式会社フラッグシステムズ</td>
            </tr>
            <tr>
              <th>住所</th>
              <td>
                〒160-0023 東京都新宿区西新宿7-1-7 新宿ダイカンプラザA館1119号室
              </td>
            </tr>
            <tr>
              <th>TEL</th>
              <td>03-6908-6250</td>
            </tr>
            <tr>
              <th>FAX</th>
              <td>03-6908-6251</td>
            </tr>
            <tr>
              <th>設立</th>
              <td>2008年11月21日</td>
            </tr>
            <tr>
              <th>代表者</th>
              <td>代表取締役 平野雅史</td>
            </tr>
            <tr>
              <th>事業内容</th>
              <td>情報処理システムの企画および開発</td>
            </tr>
            <tr>
              <th>個人情報保護方針</th>
              <td>
                <Link to="/privacy/">こちら</Link>
                よりご確認ください。
              </td>
            </tr>
            <tr>
              <th>お問い合わせ</th>
              <td>
                <a href="mailto:contact@flag-systems.co.jp">こちら</a>
                からご連絡を頂くようお願いいたします。
              </td>
            </tr>
          </tbody>
        </table>
        <div id="company-map" className="wow fadeInUp" data-wow-duration="0.8s">
          <Iframe
            url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3240.2923909909796!2d139.69907870000003!3d35.69442175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188cd7b41a140d%3A0x7336b7ddf451108e!2z44CSMTYwLTAwMDAg5p2x5Lqs6YO95paw5a6_5Yy66KW_5paw5a6_77yX5LiB55uu77yR4oiS77yXIOaWsOWuv-ODgOOCpOOCq-ODs-ODl-ODqeOCtu-8oemkqA!5e0!3m2!1sja!2sjp!4v1407755715545"
            height="480px"
          />
        </div>
      </div>
      <div className="silhouette-container">
        <a href="/#" className="silhouette-image scroll-to-top">
          scroll to top
        </a>
      </div>
    </div>
  </Container>
);

export default Company;
