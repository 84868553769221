import styled from 'styled-components';
import SectionWrapper from '../../styles/SectionWrapper';
import Colors from '../../consts/Colors';
import backgroundBusiness from '../../assets/background_business.png';
import headerBusiness from '../../assets/header_business.png';
import markerGray from '../../assets/marker_gray.png';
import silhouetteBusiness from '../../assets/silhouette_business.png';

export default styled(SectionWrapper)`
  background: url(${backgroundBusiness}) 50% 0 no-repeat fixed;
  background-size: cover;
  background-color: #c4b9ac; // 背景画像が切れたときに白背景にならないための保険
  h1 {
    background: url(${headerBusiness}) no-repeat 0 0;
  }
  div.business-content {
    margin-top: 30px;
    h2 {
      margin-bottom: 20px;
      margin-left: 23px;
      margin-right: 23px;
    }
    h3 {
      margin-bottom: 5px;
      margin-left: 40px;
      margin-right: 40px;
      padding-left: 15px; // marker表示のため
      background: url(${markerGray}) no-repeat 0px 50%;
    }
    p {
      font-size: 14px;
      color: ${Colors.textContent};
      margin-left: 55px; // h3の左余白に合わせるよう余分に余白を取る
      margin-right: 20px;
      margin-bottom: 20px;
      line-height: 1.5em;
    }
  }
  /* マウスオーバーでシルエット画像切り替え */
  .silhouette-image {
    background: url(${silhouetteBusiness}) no-repeat 0 0;
  }
  .silhouette-image:hover {
    background-position: 0 bottom;
  }
`;
