import React from 'react';
import AnchorTarget from '../../components/AnchorTarget';
import Container from './style';

const Message: React.FC = () => (
  <Container>
    <AnchorTarget id="message" />
    <div className="container">
      <div className="section-content">
        <h1 className="wow fadeInDown">Message</h1>
        <div className="message-content wow pulse" data-wow-duration="0.8s">
          <h2>「便利で」「楽しい」世界へ向けて</h2>
          <p>
            いつの頃からか、世の中に情報があふれかえるようになりました。
            ここ最近のことです。
            近所の図書館まで自転車で出かけていたはずなのに、今ではGoogleの窓に聞きたいことを入れて検索ボタンをクリックするようになっていませんか？
            ひょっとしたら、それは携帯電話から片手で行っているのかもしれません。
            Wikipediaが大概のことを教えてくれるようになりましたし、あの有名人のつぶやきでさえ瞬時に聞くことができます。
          </p>
          <p>
            日常がこれだけ変わっているのだから、当然ビジネスも変わっています。
            紙の帳簿を使っている企業なんて見かけることはなくなりました。コンピュータが複雑な計算を肩代わりしてくれます。無駄遣いも簡単にあぶりだせます。
            お取引先とはメールでやり取りをします。メールすら面倒になると専用のシステムで大量の情報を交換し合います。
            いつの頃からか、世の中には情報化社会に変貌してしまいました。
          </p>
          <p>
            私はずっと情報システム開発に携わってきました。情報システムは情報を動かす中心となるツールです。
            その中で私は、情報（システム）に使われるように感じて疲弊しているお客様の声を聞いてきました。
            また、世の中の変化のスピードについていけない業者とそのために求める情報（システム）を得られないお客様の声を聞いてきました。
            つぶやきは得られても、本当に自分自身が欲しい情報（システム）だけを得ることは難しいようです。
            いつの間にやら情報を提供し、操作する側となってしまった私は、本当に使いたいシステムを提供する責務があるのだろうと思うようになりました。
          </p>
          <p>
            艦隊の司令官が乗って指揮を行う軍艦を旗艦（FlagShip）といいます。転じて、最上級のものをフラッグシップとよびます。
            システム業界における旗艦（FlagShip）を目指し、FlagSystemsと名づけました。
            システムは、もっとシンプルで、便利で、楽しいものだと確信しています。
            FlagSystemsは、楽しく便利な世界を実現できるよう、最上級のシステムを提供できるよう、邁進してまいります。
          </p>
          <p className="signature">
            株式会社 FlagSystems
            <br />
            代表取締役 平野雅史
          </p>
        </div>
      </div>
      <div className="silhouette-container">
        <span className="silhouette-image" />
      </div>
    </div>
  </Container>
);

export default Message;
