import React from 'react';
import AnchorTarget from '../../components/AnchorTarget';
import Container from './style';

const Business: React.FC = () => (
  <Container>
    <AnchorTarget id="business" />
    <div className="container">
      <div className="section-content">
        <h1 className="wow fadeInDown">事業内容</h1>
        <div className="business-content">
          <h2 className="wow fadeInLeft" data-wow-delay="0.6s">
            ソフトウェア開発事業
          </h2>
          <h3 className="wow fadeInRight" data-wow-delay="0.6s">
            情報システム開発
          </h3>
          <p>
            オープン系WEBシステムを中心とした情報システムの構築を、要件定義から設計、製造までを一環して、また、その一部を請け賜ります。
            財務会計、金融、医療、物流、各業種の各種システムの構築に携わってきた中で、長年培った経験・ノウハウがございます。
            プロジェクトの管理、アーキテクチャの検討、業務要件・システム要件の決定から、実際の業務設計・製造まで幅広く対応いたします。
            <br />
            また、Java、PHPといった言語から、Ajax、Flexを中心としたリッチクライアント技術まで、各種技術提供もいたします。
            <br />
            お客様に満足いただくことを、信頼いただけることを第一義に、システム、及び、技術を提供させていただきます。
          </p>
          <h3 className="wow fadeInRight" data-wow-delay="0.6s">
            WEBサービス開発
          </h3>
          <p>
            インターネットを通じて、法人、一般を問わず、様々なサービスのご提供いたします。
            コンピュータの導入、システムの構築・運用・管理といった専門業務から開放され、また、短期間で利用開始することができます。
            日々の業務改善は当然のこととして、業務をもっと楽しくをモットーに、新たなサービスの事業化にチャレンジして参ります。
          </p>
          <h3 className="wow fadeInRight" data-wow-delay="0.6s">
            アウトソーシング
          </h3>
          <p>
            情報システム開発、WEBサービス開発を行うSIer様に対しての人的リソースの提供をいたします。
            高い業務遂行能力、高度な専門技術知識を持っているのはもちろんのこと、改善、提案ができる人材を揃えております。
            プロジェクトの目的・目標を達成することに全力を注ぎます。
          </p>
        </div>
      </div>
      <div className="silhouette-container">
        <span className="silhouette-image" />
      </div>
    </div>
  </Container>
);

export default Business;
