import styled from 'styled-components';
import SectionWrapper from '../../styles/SectionWrapper';
import Colors from '../../consts/Colors';
import markerWhite from '../../assets/marker_white.png';
import iconExternalLinkWhite from '../../assets/icon_external_link_white.png';
import topWave from '../../assets/top_wave.png';

export const TopicContainer = styled.div`
  font-size: 14px;
  color: #ffffff;
  line-height: 2em;
  margin-top: 0;
  margin-bottom: 2px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  span.published-date {
    padding-left: 15px; // marker表示のため
    background: url(${markerWhite}) no-repeat 0px 50%;
  }
  span.title {
    padding-left: 0;
    a {
      background: url(${iconExternalLinkWhite}) no-repeat right center;
      padding-right: 18px;
    }
    a:visited {
      color: #ffffff;
    }
    a:link {
      color: #ffffff;
    }
  }
`;

export const TopicsContainer = styled.div``;

export const TopContainer = styled(SectionWrapper)`
  /* navbar分の余白 */
  margin-top: 55px;
  background: url(${topWave}) 50% 0 no-repeat fixed;
  /* 表示領域にピッタリ合わせる */
  background-size: contain;
  #main-content {
    ul {
      margin: 0;
      list-style: none;
      /* flexbox を使い li を上揃え＆下揃えに配置している。 */
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  #topics-list {
    margin: 0 auto;
    padding: 0 10px 50px;
    border-top: 4px solid ${Colors.topTopicsHeader};
    background-image: linear-gradient(
      ${Colors.topTopicsBorderTop} 0,
      ${Colors.topTopicsBorderBottom} 100%
    );
    h2 {
      font-size: 14px;
      color: ${Colors.textHeader};
      text-align: center;
      margin-top: 0;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      padding: 3px;
      max-width: 600px;
      background-color: #ffffff;
      border-radius: 0 0 5px 5px; // bottomのみ角丸
    }
  }
  @media screen and (min-width: 1024px) {
    /* 高さいっぱいに表示。100から余分なnavbarなどの高さを引いている。*/
    height: calc(100vh - 55px - 30px);
    /* flexbox を使い #main-content, #topics-list を下揃えで配置している。 */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;
