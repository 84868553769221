import React from 'react';
import Component from './style';

type Props = {
  id: string;
};

const AnchorTarget: React.FC<Props> = ({ id }) => <Component id={id} />;

export default AnchorTarget;
