import styled from 'styled-components';
import SectionWrapper from '../../styles/SectionWrapper';
import Colors from '../../consts/Colors';
import headerMessage from '../../assets/header_message.png';
import silhouetteMessage from '../../assets/silhouette_message.png';

export default styled(SectionWrapper)`
  background-image: linear-gradient(#fef8ee 0, #f9e5c0 100%);
  h1 {
    background: url(${headerMessage}) no-repeat 0 0;
  }
  div.message-content {
    margin-top: 30px;
    padding: 30px;
    background-color: ${Colors.message};
    border-radius: 8px;
    h2 {
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 15px;
    }
    p {
      font-size: 12px;
      color: #ffffff;
      line-height: 1.4em;
      margin-bottom: 15px;
    }
    p.signature {
      text-align: right;
    }
  }
  /* マウスオーバーでシルエット画像切り替え */
  .silhouette-image {
    background: url(${silhouetteMessage}) no-repeat 0 0;
  }
  .silhouette-image:hover {
    background-position: 0 bottom;
  }
`;
