import styled from 'styled-components';
import SectionWrapper from '../../styles/SectionWrapper';
import backgroundCompany from '../../assets/background_company.png';
import headerCompany from '../../assets/header_company.png';
import markerGreen from '../../assets/marker_green.png';
import silhouetteCompany from '../../assets/silhouette_company.png';

export default styled(SectionWrapper)`
  background: url(${backgroundCompany}) 50% 0 no-repeat fixed;
  background-size: cover;
  background-color: #dddec8; // 背景画像が切れたときに白背景にならないための保険
  h1 {
    background: url(${headerCompany}) no-repeat 0 0;
  }
  table {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    th {
      vertical-align: middle;
      background-image: url(${markerGreen});
      background-repeat: no-repeat;
      background-position: 0px 50%; // markerを立てに中央揃え
      padding: 8px;
      padding-left: 20px; // marker表示のため
      width: 150px; // 画面サイズが小さい時にヘッダー行は幅を稼ぐ
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
    }
    td {
      vertical-align: middle;
      padding: 5px;
      font-size: 13px;
    }
  }
  #company-map {
    margin-top: 50px;
    margin-left: auto; // 中央揃え
    margin-right: auto; // 中央揃え
    max-width: 700px;
    /* 地図表示iframeに適用されているuser agent styleのボーダー指定を無効化 */
    iframe {
      border-style: none;
    }
  }
  /* googlemapのレスポンシブ対応 参考) http://inthecom.net/?p=718 */
  #company-map {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  /* マウスオーバーでシルエット画像切り替え */
  .silhouette-image {
    background: url(${silhouetteCompany}) no-repeat 0 0;
    /* 背景画像は表示しテキストは表示しない。es-lint の anchor-has-content のための対策 */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
  }
  .silhouette-image:hover {
    background-position: 0 bottom;
  }
`;
